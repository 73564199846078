import { Injectable } from '@angular/core';

@Injectable()
export class PrintService {
  public print(htmlRaw: string): void {
    if (window.print) {
      const printWindow = window.open('');

      printWindow.document.write(`
      <html>
        <head>
          <link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap" rel="stylesheet">

          <link href="//cdn.quilljs.com/1.3.6/quill.core.css" rel="stylesheet">
          <link href="//cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet">

          <style>
            * {
              visibility: hidden;
            }

            html, body {
              height: auto;
              margin: 0;
            }

            @media print {
              * {
                font-family: Manrope, sans-serif;
                color: #262b2f;
                visibility: visible;
              }

              .title {
                display: block;
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 32px;
              }

              .ql-container,
              .ql-editor {
                height: auto;
              }

              .ql-editor {
                font-size: 14px;
                padding: 0;
                overflow-y: unset;
              }

              .ql-editor p,
              .ql-editor span {
                margin: 0;
                line-height: 156%;
                letter-spacing: 0.2px;
                color: #000000;
              }

              .ql-editor h1,
              .ql-editor h2,
              .ql-editor h3 {
                font-family: unset;
                font-size: unset;
                font-weight: 700;
              }

              .ql-editor h1 {
                font-size: 22px;
              }

              .ql-editor  h2 {
                font-size: 20px;
              }

              .ql-editor  h3 {
                font-size: 18px;
              }

              .watermark {
                display: none;
              }
            }
          </style>
        </head>
        <body onload="print();close();">
          ${htmlRaw}
        </body>
      </html>
      `);
      printWindow.document.close();
      printWindow.focus();
    }
  }
}
